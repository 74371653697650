import React, { useState } from "react";

// Assets
import { ReactComponent as ArrowLeft } from "../PropagoCheckout/assets/svgs/arrowRightHeader.svg";
import { ReactComponent as ArrowRight } from "../PropagoCheckout/assets/svgs/arrowRightHeader.svg";

// Stylesheets
import { CarouselContainer } from "./styles";

const Card = ({ item, imagePath, photoNumber, position }) => (
  <div className="card-container" data-card-item={position}>
    <div className="card-item">
      <div
        className="card-item__image"
        style={{
          backgroundImage: `url(/assets/${imagePath}-img${photoNumber}.webp)`,
        }}
      ></div>
      <div className="card-item__text">
        <h3>{item.title}</h3>
        <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
      </div>
    </div>
  </div>
);

const Carousel = ({ slides, imagePath }) => {
  let [showingSlides, setShowingSlides] = useState(
    slides.length >= 5
      ? slides.slice(-2).concat(slides.slice(0, 3))
      : slides.slice(-1).concat(slides.slice(0, 2))
  );
  let [imagesIndexesList, setImagesIndexesList] = useState(
    slides.length >= 5
      ? [slides.length - 1, slides.length, 1, 2, 3]
      : [slides.length, 1, 2]
  );
  let [activeSlide, setActiveSlide] = useState(
    Math.floor(showingSlides.length / 2)
  );

  let defineNextAndPreviousSlides = (direction) => {
    let copiedSlideList = showingSlides.slice();
    let copiedImageList = imagesIndexesList.slice();

    let actualSlideIndex =
      parseInt(
        document.querySelector("[data-active-card-item]").dataset.activeCardItem
      ) + direction;
    let indexOfMatchedObject = slides.findIndex(
      (item) =>
        item.title ===
        copiedSlideList.at(actualSlideIndex % copiedSlideList.length).title
    );
    let indexLimit = actualSlideIndex + Math.floor(copiedSlideList.length / 2);

    for (let i = actualSlideIndex + 1; i <= indexLimit; i++) {
      let adder = i - actualSlideIndex;

      let previousSlideIndex =
        actualSlideIndex - adder < 0
          ? (Math.abs(actualSlideIndex - adder) *
              (copiedSlideList.length - 1)) %
            copiedSlideList.length
          : actualSlideIndex - adder;

      copiedImageList[previousSlideIndex] =
        indexOfMatchedObject - adder < 0
          ? (Math.abs((indexOfMatchedObject - adder) * (slides.length - 1)) %
              slides.length) +
            1
          : indexOfMatchedObject - adder + 1;
      copiedSlideList[previousSlideIndex] = slides.at(
        indexOfMatchedObject - adder
      );

      copiedSlideList[(actualSlideIndex + adder) % copiedSlideList.length] =
        slides.at((indexOfMatchedObject + adder) % slides.length);
      copiedImageList[(actualSlideIndex + adder) % copiedImageList.length] =
        ((indexOfMatchedObject + adder) % slides.length) + 1;
    }

    setImagesIndexesList(copiedImageList);
    return copiedSlideList;
  };

  return (
    <CarouselContainer className="d-flex column-gap-5">
      <div className="d-flex align-items-center justify-content-center">
        <button
          onClick={() => {
            setActiveSlide((i) => i - 1);
            setShowingSlides(() => defineNextAndPreviousSlides(-1));
          }}
          style={{ transform: "rotateY(-180deg)" }}
          aria-label="Go back button"
        >
          <ArrowLeft width={32} height={32} fill="#E7DB32" />
        </button>
      </div>
      <div
        className={
          showingSlides.length < 5
            ? "small-carousel-container"
            : "carousel-container"
        }
        data-active-card-item={
          activeSlide % showingSlides.length < 0
            ? showingSlides.length -
              Math.abs(activeSlide % showingSlides.length)
            : activeSlide % showingSlides.length
        }
      >
        {showingSlides.map((slide, index) => (
          <Card
            item={slide}
            imagePath={imagePath}
            photoNumber={imagesIndexesList[index]}
            position={index}
            key={
              showingSlides.length < 5
                ? `contained-card${index}`
                : `card${index}`
            }
          />
        ))}
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <button
          onClick={() => {
            setActiveSlide((i) => i + 1);
            setShowingSlides(() => defineNextAndPreviousSlides(1));
          }}
          aria-label="Go forward button"
        >
          <ArrowRight width={32} height={32} fill="#E7DB32" />
        </button>
      </div>
    </CarouselContainer>
  );
};

export default Carousel;
