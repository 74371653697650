import styled from "styled-components";
import devices from "../../utils/helperGrids";

export const Wrapper = styled.div`
  background-color: #FFF;
  z-index: 3;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-top: 25px;
  padding-bottom: 35px;

  .wrapper-button {
    margin: 25px 0;
  }

  @media ${devices.md} {
    .wrapper-content {
      padding-right: 40px;
      margin-top: 0px;
    }
  }
  @media ${devices.lg} {
    .wrapper-content {
      padding-right: 60px;
    }
  }
`;

export const WrapperText = styled.div`
  ${({ hasTitle }) =>
    hasTitle
      ? `
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    color: #231E25;
    margin-bottom: 24px;
  `
      : ""}

  ${({ hasDescription }) =>
    hasDescription
      ? `
    font-size: 15px;
    line-height: 24px;
    font-weight: 200;
    color: #231E25;
  `
      : ""}
`;
