import { useState, lazy, Suspense } from "react";

// Components
import SectionCookies from "./components/SectionCookies";
import ButtonDonateNow from "./components/ButtonDonateNow";
import HEAD00002 from "./components/Wireframes/HEAD00002";
import COMP00018 from "./components/Wireframes/COMP00018";

// Translations
import { startI18N } from "./i18n";
import i18nTranslations from "./whitelabels/acto/i18n";

// Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";

// Lazy Loaded Components
const COMP00005 = lazy(() => import("./components/Wireframes/COMP00005"));
const COMP00001 = lazy(() => import("./components/Wireframes/COMP00001"));
const COMP_ALLYA = lazy(() => import("./components/Wireframes/COMP_ALLYA"));
const CHEC00001 = lazy(() => import("./components/Wireframes/CHEC00001"));
const FAQS00001 = lazy(() => import("./components/Wireframes/FAQS00001"));
const COMP_GEOL = lazy(() => import("./components/Wireframes/COMP_GEOL"));
const FOOT00001 = lazy(() => import("./components/Wireframes/FOOT00001"));

const App = () =>  {
  const [acceptedCookies, setAcceptedCookies] = useState(false);

  let dirName = 'acto';

  startI18N(i18nTranslations);

  return (
    <>
      <div className="lp-app notranslate">
        <HEAD00002 dirName={dirName} />
        <COMP00018 dirName={dirName} />
        <ButtonDonateNow
          primaryColor="#DF473E"
          secondaryColor="#FFFFFF"
          borderFixedColor="#FFFFFF"
          borderFixedHoverColor="#DF473E"
        />
        {SectionCookies != '' && acceptedCookies === false && (
          <SectionCookies onAccept={() => setAcceptedCookies(true)} />
        )}
        <Suspense>
          <COMP00005 dirName={dirName} />
        </Suspense>
        <Suspense>
          <COMP00001 dirName={dirName} />
        </Suspense>
        <Suspense>
          <COMP_ALLYA />
        </Suspense>
        <Suspense>
          <CHEC00001 />
        </Suspense>
        <Suspense>
          <FAQS00001 />
        </Suspense>
        <Suspense>
          <COMP_GEOL url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3689.313441460355!2d-41.77739379860544!3d-22.37953823024629!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x963061c632470d%3A0xfe55e181d63a48d4!2sRua%20Dr.%20J%C3%BAlio%20Olivier%2C%20633%20-%20Centro%2C%20Maca%C3%A9%20-%20RJ%2C%2027916-211!5e0!3m2!1spt-BR!2sbr!4v1709149729998!5m2!1spt-BR!2sbr"/>
        </Suspense>
        <Suspense>
          <FOOT00001 dirName={dirName} />
        </Suspense>
      </div>
    </>
  );
}

export default App;