import i18n_translations from "../../components/PropagoCheckout/i18n/translations";

const i18n = {
  "pt-BR": {
    translations: {
      Page: {
        title: "Desperte a Arte em Macaé: Apoie o Grupo ACTO e Faça a Diferença na Nossa Comunidade",
      },
      ButtonFloating: {
        button: "Doe Agora",
        buttonBottom: "Faça uma doação",
      },
      HEAD00002: {
        title: "Desperte a Arte em Macaé: apoie o Grupo ACTO",
        subtitle: `<p><strong>O Grupo Teatral ACTO</strong> é um agente cultural na cidade de Macaé com 37 anos de história,
        que já organizou mais de 45 montagens teatrais. Há 15 anos, o grupo comanda o <strong>Centro Cultural Rinha das Artes</strong>,
        espaço próprio que marca o cenário artístico da cidade.</p>
        <p>Somos apaixonados por arte e cultura e sabemos de seu valor para a transformação social.
        Ajude-nos e garanta que o Centro Cultural Rinha das Artes continue sendo um espaço
        vibrante e inspirador para todos. Sua doação não é apenas um gesto generoso, mas um
        investimento no desenvolvimento de Macaé e no crescimento pessoal de muitos gestores
        culturais e artistas.</p>`,
        button: "Invista em um futuro mais vibrante",
      },
      COMP00018: {
        title: "Por Que Apoiar o Grupo Teatral ACTO?",
        altText: "Foto de",
        cards: [
          {
            title: "Cultura Acessível",
            content: `Somos defensores da cultura acessível a todos. Sua doação ajuda a
            manter o <strong>Centro Cultural Rinha das Artes</strong> aberto a todos os membros da comunidade,
            proporcionando experiências sócio-culturais enriquecedoras`,
          },
          {
            title: "Projetos Inovadores",
            content: `Transformamos um prédio que era uma rinha de galo em um espaço
            cultural referência na nossa cidade. Não temos medo de explorar novos horizontes, de
            criar o diferente. Ao apoiar, você impulsiona projetos inovadores que desafiam e inspiram,
            tornando Macaé um centro cultural dinâmico.`,
          },
          {
            title: "Espaço De Expressão Artística",
            content: `O <strong>Centro Cultural Rinha das Artes</strong> é mais do que um
            local físico; é um lugar para artistas locais e emergentes exercerem sua criatividade. Sua
            doação ajuda a manter esse santuário para a expressão artística.`
          },
        ],
        bottomDescription: "Somente em 2023, 5 a 7 mil pessoas foram impactadas com nossa atuação no Centro Cultural Rinha das Artes.",
        button: "Ajude-nos a atingir mais pessoas. Doe agora!",
      },
      COMP00005: {
        title: "Como Sua Doação Faz a Diferença?",
        cards: [
          {
            title: 'Manutenção do Espaço:',
            text: `Sua contribuição ajuda a cobrir os custos operacionais do Centro
            Cultural Rinha das Artes, garantindo que o local permaneça aberto para eventos e
            performances.`,
          },
          {
            title: 'Patrocínio a Artistas Locais:',
            text: `Com seu apoio, o Grupo Teatral ACTO pode oferecer
            oportunidades a artistas locais, promovendo o talento emergente e incentivando a cena
            cultural de Macaé.`,
          },
          {
            title: 'Programas Educativos:',
            text: `Sua doação possibilita a implementação de programas educativos,
            workshops e palestras, enriquecendo a comunidade com conhecimento e inspiração.`,
          },
        ],
        button: "Promova a vida cultural de Macaé",
      },
      COMP00001: {
        title: "A Importância Da Sua Doação",
        subTitle: `Sua doação é a chave para sustentar a vitalidade cultural de Macaé. Juntos, podemos
        garantir que Centro Cultural Rinha das Artes continue sendo um ponto de encontro para
        a diversidade cultural, a expressão artística e a comunidade. Faça parte dessa jornada e
        seja um agente de mudança cultural em nossa cidade.`,
        button: "Faça Sua Doação Hoje",
      },
      COMP_ALLYA: {
        title: `Faça parte do `,
        description: `
          <p>Sua doação é muito importante para nós e faz toda a diferença. O processo de <strong>doação</strong> é simples, seguro e fácil. O dinheiro doado é revertido para as nossas ações sociais e filantrópicas. Você pode doar uma única vez ou todos os meses, de forma recorrente. Quer sua doação seja grande ou pequena, saiba que ela é uma prova do seu compromisso com um mundo melhor para todos. Junte-se a nós hoje na criação de um futuro onde o poder duradouro do amor prevaleça. </p>
          <p><strong>Doe todos os meses</strong> para <strong>Grupo Acto</strong> e participe do nosso <strong>Clube do Bem</strong>, uma parceria altruísta e  filantrópica entre <strong>Propago e Allya.</strong></p>
          <p>No Clube do Bem da Propago  você irá economizar dinheiro, tornar seu dia a dia mais prático e o melhor de tudo, sua economia pode virar uma doação!</p>
          <p>Você contará com uma série de <strong>descontos, vantagens e benefícios</strong> em mais de <strong>30 mil estabelecimentos</strong> como: Netshoes, Magazine Luiza, Cinemark, Droga Raia, EspaçoLaser e muito mais! </p>
        `,
        list: [
          {
            area: 'Beleza e bem-estar! -',
            discount: 'Até 80%'
          },
          {
            area: 'Lazer -',
            discount: 'Até 50% OFF'
          },
          {
            area: 'Restaurantes -',
            discount: 'Até 35% OFF'
          },
          {
            area: 'Cursos e Faculdades -',
            discount: 'Até 80%'
          },
        ],
        bottomDescription: `Viu como sua economia pode gerar o bem para todo o ecossistema?`,
        text: `A <strong style="color: #F73D6A;">Allya</strong> tem como objetivo ajudar pessoas do bem  a manterem um padrão de vida mais econômico de forma inteligente, levando consigo a filantropia!`,
      },
      CHEC00001: {
        title: "Sua doação irá <strong>transformar a vida</strong> de alguém.",
        legend: "Apoio",
      },
      FAQS00001: {
        title: "<strong>Perguntas Frequentes</strong> sobre a doação:",
        cards: [
          {
            title: "Devo declarar as doações no imposto de renda?",
            subTitle:
              "Em conformidade com as regras da Receita Federal do Brasil, o doador precisa informar todas as doações realizadas em suas declarações financeiras. Lembrando que, segundo o Regulamento do Imposto de Renda – RIR (Decreto nº 9.580, de 22/11/ 2018), não há dedução no Imposto de Renda de Pessoa Física (IRPF) para doações a projetos como os realizados pelo Grupo Acto.<br /><br />O valor doado deve ser declarado na ficha DOAÇÕES EFETUADAS sob o código 99 – Outras doações, uma vez que se trata de doação não incentivada (não permite dedução do IR a pagar do doador).",
          },
          {
            title: "Quais são as formas de pagamento para doações no site?",
            subTitle:
              "Você pode fazer sua doação, seja ela pontual ou recorrente, via boleto bancário, cartão de crédito (todas as bandeiras) ou via pix.",
          },
          {
            title: "Por que o Grupo Acto emitiu uma cobrança em meu nome?",
            subTitle:
              "Para que os pagamentos sejam mais transparentes e seguros, a Federação Brasileira de Bancos (Febraban) instituiu que todos os boletos sejam registrados em uma plataforma de cobrança desenvolvida pela própria Febraban, que permite aos bancos informar ao cliente toda vez que um novo boleto for emitido em seu nome. Clientes cadastrados no serviço Débito Direto Autorizado (DDA) podem ser informados pelos bancos sobre a cobrança no momento do registro do boleto, pelos canais digitais ou com o envio de torpedos.",
          },
          {
            title: "Se eu não pagar o boleto, meu nome pode ser negativado?",
            subTitle:
              "Não. O pagamento dos boletos enviados pelo Grupo Acto é totalmente facultativo.",
          },
          {
            title:
              "Meu boleto veio com um valor que eu não quero doar, o que devo fazer?",
            subTitle:
              "Você pode escolher algum dos valores sugeridos para doação, ou definir uma outra quantia que seja de sua preferência, clicando no botão “outro valor”. Se o valor do boleto emitido não for igual ao que você pretende doar, pode emitir outro.",
          },
          {
            title: "O que eu faço se meu boleto vier sem código de barras?",
            subTitle:
              "Se você recebeu um boleto sem código de barras, isso indica que você está cadastrado no Débito Direto Autorizado (DDA). Por determinação das novas regras da Febraban, clientes cadastrados no serviço DDA não recebem mais boletos com códigos de barras. Para pagar seu boleto basta autorizar o débito junto ao seu banco, pela internet, pelo telefone ou nos caixas eletrônicos.",
          },
          {
            title: "O que é DDA?",
            subTitle:
              "O Débito Direto Autorizado (DDA) é um sistema criado pela Federação Brasileira de Bancos (Febraban) que substitui a emissão de boletos de cobrança impressos pela cobrança eletrônica dos pagamentos. Para ler o comunicado no site da Febraban, acesse: <a href='https://portal.febraban.org.br/pagina/3051/1088/pt-br/dda' target='_blank' aria-label='Acesse o portal da Febrapan'>https://portal.febraban.org.br/pagina/3051/1088/pt-br/dda</a>",
          },
          {
            title: "Como posso me cadastrar no DDA?",
            subTitle:
              "Isso é um serviço oferecido pelos bancos. Para se cadastrar no Débito Direto Autorizado (DDA), basta entrar em contato com o seu banco, por telefone, pela internet ou nos caixas eletrônicos. Mesmo após o cadastro, você continua recebendo o boleto impresso; porém, sem o código de barras.",
          },
          {
            title: "Posso me descadastrar do DDA?",
            subTitle: "Para se descadastrar, entre em contato com o seu banco.",
          },
          {
            title:
              "Quero pagar meu boleto, mas a data de vencimento passou. Preciso emitir segunda via?",
            subTitle:
              "Não. Agora os boletos vencidos podem ser pagos em qualquer banco, pela internet, nos caixas eletrônicos ou em casas lotéricas.",
          },
        ],
      },
      COMP_GEOL: {
        title: `Visite-nos!`,
        iframeTitle: "Localização do escritório de Grupo Acto em Macaé"
      },
      FOOT00001: {
        copyright:
          "Copyright © 2024 - Grupo Acto ® - Todos os direitos podem ser compartilhados com você.",
        copyrightSpan: "Pergunte-nos como.",
      },
      SectionCookies: {
        title: "A Grupo Acto se preocupa com você e com a sua privacidade",
        subTitle:
          "O nosso site usa cookies e outras tecnologias para personalizar a sua experiência e compreender como você e os outros visitantes usam o nosso site. Ao navegar pelo site, coletaremos tais informações para utilizá-las com estas finalidades. Caso não aceite, usaremos os cookies apenas necessários para o correto funcionamento do site",
        button: "Aceito",
      },
      Checkout: i18n_translations["pt-BR"].translations,
    },
  },
  "en-US": {
    translations: {
      Page: {
        title: "Awaken Art in Macaé: Support the ACTO Group and Make a Difference in Our Community",
      },
      ButtonFloating: {
        button: "Donate Now",
        buttonBottom: "Make a donation",
      },
      HEAD00002: {
        title: "Awaken Art in Macaé: Support Grupo ACTO",
        subtitle: `<p><strong>The ACTO Theater Group</strong> is a cultural force in the city of Macaé, with 37 years of history.
        They have produced over 45 theatrical performances. For the past 15 years, the group has overseen the <strong>Rinha das Artes Cultural Center</strong>,
        a dedicated space that defines the city's artistic landscape.</p>
        <p>We are passionate about art and culture, recognizing their value for social transformation.
        Help us ensure that the Rinha das Artes Cultural Center remains a vibrant and inspiring space for all.
        Your donation is not just a generous gesture; it's an investment in Macaé's development and the personal growth of many cultural managers and artists.</p>`,
        button: "Invest in a more vibrant future",
      },
      COMP00018: {
        title: "Why Support the ACTO Theater Group?",
        altText: "Photo by",
        cards: [
          {
            title: "Accessible Culture",
            content: `We advocate for culture that is accessible to everyone. Your donation helps
            keep the <strong>Rinha das Artes Cultural Center</strong> open to all community members,
            providing enriching socio-cultural experiences.`,
          },
          {
            title: "Innovative Projects",
            content: `We transformed a building that was once a cockfighting pit into a
            cultural landmark in our city. We fearlessly explore new horizons, embrace the unconventional,
            and by supporting us, you drive innovative projects that challenge and inspire,
            making Macaé a dynamic cultural hub.`,
          },
          {
            title: "Space for Artistic Expression",
            content: `The <strong>Rinha das Artes Cultural Center</strong> is more than a
            physical location; it's a place for local and emerging artists to unleash their creativity.
            Your donation helps maintain this sanctuary for artistic expression.`,
          },
        ],
        bottomDescription: "In 2023 alone, our work at the Rinha das Artes Cultural Center impacted 5,000 to 7,000 people.",
        button: "Help us reach more people. Donate now!",
      },
      COMP00005: {
        title: "How Your Donation Makes a Difference?",
        cards: [
          {
            title: 'Space Maintenance:',
            text: `Your contribution helps cover the operational costs of the Rinha das Artes Cultural Center, ensuring that the venue remains open for events and performances.`,
          },
          {
            title: 'Supporting Local Artists:',
            text: `With your support, the ACTO Theater Group can provide opportunities to local artists, promoting emerging talent and encouraging Macaé's cultural scene.`,
          },
          {
            title: 'Educational Programs:',
            text: `Your donation enables the implementation of educational programs, workshops, and lectures, enriching the community with knowledge and inspiration.`,
          },
        ],
        button: "Promote Macaé's Cultural Life",
      },
      COMP00001: {
        title: "The Importance of Your Donation",
        subTitle: `Your donation is the key to sustaining Macaé's cultural vitality. Together, we can
        ensure that the Rinha das Artes Cultural Center continues to be a meeting point for
        cultural diversity, artistic expression, and the community. Join this journey and
        be an agent of cultural change in our city.`,
        button: "Make Your Donation Today",
      },
      COMP_ALLYA: {
        title: 'Join the ',
        description: `
          <p>Your donation is very important to us and makes all the difference. The process of <strong>donation</strong> is simple, secure, and easy. The donated money is reverted to our social and philanthropic actions. You can donate once or every month, recurrently. Whether your donation is big or small, know that it is proof of your commitment to a better world for everyone. Join us today in creating a future where the lasting power of love prevails.</p>
          <p><strong>Donate every month</strong> to <strong>Grupo Acto</strong> and participate in our <strong>Clube do Bem</strong> (Club of Good), an altruistic and philanthropic partnership between <strong>Propago and Allya.</strong></p>
          <p>In Propago's Clube do Bem you will save money, make your day-to-day more practical and best of all, your savings can turn into a donation!</p>
          <p>You will have a series of <strong>discounts, advantages, and benefits</strong> in more than <strong>30 thousand establishments</strong> such as: Netshoes, Magazine Luiza, Cinemark, Droga Raia, EspaçoLaser and much more!</p>
        `,
        list: [
          {
            area: 'Beauty and well-being! -',
            discount: 'Up to 80% OFF'
          },
          {
            area: 'Leisure - ',
            discount: 'Up to 50% OFF'
          },
          {
            area: 'Restaurants -',
            discount: 'Up to 35% OFF'
          },
          {
            area: 'Courses and Undergraduations -',
            discount: 'Up to 80% OFF'
          },
        ],
        bottomDescription: `<p>See how your economy can do good for the whole ecosystem?</p>`,
        text: `<strong style="color: #F73D6A;">Allya</strong> aims to aims to help good people maintain a more economical standard of living in an intelligent way, bringing philanthropy with it!`,
      },
      CHEC00001: {
        title: "Your donation will <strong>change someone's life</strong>",
        legend: "By",
      },
      FAQS00001: {
        title: "<strong>Frequently Asked Questions</strong> about the donation:",
        cards: [
          {
            title: "Does Grupo Acto accept donations in kind",
            subTitle:
              "Unfortunately, we cannot accept donations in kind.",
          },
          {
            title: "How can i be sure my online donation has been accepted?",
            subTitle:
              "If you entered a valid email address, you will receive a message confirming your donation. If you don't receive an email within about 15 minutes, please contact us.",
          },
          {
            title: "What are the payment methods for donations on the website?",
            subTitle: "You can make your donation, whether punctual or recurring by credit card."
          },
          {
            title: "Why is monthly giving the best option?",
            subTitle:
              "Monthly giving is the best option for both Grupo Acto and our supporters. It allows us to have a dependable base of support and provides predictable funding. For our supporters it is the easiest way to donate, since your credit card deducts your giving automatically.",
          },
          {
            title: "Is the donation page secure?",
            subTitle: "We are proud to meet strict data security standards in order to process your donation online.",
          },
        ],
      },
      COMP_GEOL: {
        title: `Visit us!`,
        iframeTitle: "Localization of Grupo Acto's office in Ribeirão Preto - SP (Brazil)"
      },
      FOOT00001: {
        copyright:
          "Copyright © 2024 - Grupo Acto ® - All rights can be shared with you.",
        copyrightSpan: "Ask us how.",
      },
      SectionCookies: {
        title: "Grupo Acto worries about your privacy.",
        subTitle:
          "This website uses cookies and other technologies to improve your experience and to analyze the traffic in our website. Once you are browsing we will colect such information for this analysis. If you don't accept it, we will use only cookies that are needed to run the website.",
        button: "I Accept",
      },
      Checkout: i18n_translations.en.translations,
    }
  },
  es: {
    translations: {
      Page: {
        title: "Despierta el arte en Macaé: Apoya al Grupo ACTO y Marca la Diferencia en Nuestra Comunidad",
      },
      ButtonFloating: {
        button: "Dona ahora",
        buttonBottom: "Haz una donácion",
      },
      HEAD00002: {
        title: "Despierta el Arte en Macaé: Apoya al Grupo ACTO",
        subtitle: `<p><strong>El Grupo de Teatro ACTO</strong> es una fuerza cultural en la ciudad de Macaé, con 37 años de historia.
        Han producido más de 45 espectáculos teatrales. Durante los últimos 15 años, el grupo ha supervisado el <strong>Centro Cultural Rinha das Artes</strong>,
        un espacio dedicado que define el paisaje artístico de la ciudad.</p>
        <p>Somos apasionados por el arte y la cultura, reconociendo su valor para la transformación social.
        Ayúdanos a asegurar que el Centro Cultural Rinha das Artes siga siendo un espacio vibrante e inspirador para todos.
        Tu donación no es solo un gesto generoso; es una inversión en el desarrollo de Macaé y en el crecimiento personal de muchos gestores culturales y artistas.</p>`,
        button: "Invierte en un futuro más vibrante",
      },
      COMP00018: {
        title: "¿Por qué apoyar al Grupo Teatral ACTO?",
        altText: "Foto de",
        cards: [
          {
            title: "Cultura Accesible",
            content: `Somos defensores de la cultura accesible para todos. Tu donación ayuda a
                mantener el <strong>Centro Cultural Rinha das Artes</strong> abierto a todos los miembros de la comunidad,
                proporcionando experiencias socio-culturales enriquecedoras.`,
          },
          {
            title: "Proyectos Innovadores",
            content: `Transformamos un edificio que solía ser una pelea de gallos en un espacio
                cultural de referencia en nuestra ciudad. No tenemos miedo de explorar nuevos horizontes, de
                crear algo diferente. Al apoyarnos, impulsas proyectos innovadores que desafían e inspiran,
                convirtiendo a Macaé en un centro cultural dinámico.`,
          },
          {
            title: "Espacio de Expresión Artística",
            content: `El <strong>Centro Cultural Rinha das Artes</strong> es más que un
                lugar físico; es un espacio para que artistas locales y emergentes ejerzan su creatividad. Tu
                donación ayuda a mantener este santuario para la expresión artística.`,
          },
        ],
        bottomDescription: "Solo en 2023, entre 5,000 y 7,000 personas se vieron impactadas por nuestra labor en el Centro Cultural Rinha das Artes.",
        button: "¡Ayúdanos a llegar a más personas! ¡Dona ahora!",
      },
      COMP00005: {
        title: "¿Cómo Tu Donación Hace la Diferencia?",
        cards: [
          {
            title: 'Mantenimiento del Espacio:',
            text: `Tu contribución ayuda a cubrir los costos operativos del Centro Cultural Rinha das Artes, asegurando que el lugar permanezca abierto para eventos y presentaciones.`,
          },
          {
            title: 'Apoyo a Artistas Locales:',
            text: `Con tu apoyo, el Grupo Teatral ACTO puede brindar oportunidades a artistas locales, promoviendo el talento emergente e impulsando la escena cultural de Macaé.`,
          },
          {
            title: 'Programas Educativos:',
            text: `Tu donación posibilita la implementación de programas educativos, talleres y charlas, enriqueciendo a la comunidad con conocimiento e inspiración.`,
          },
        ],
        button: "Promueve la vida cultural de Macaé",
      },
      COMP00001: {
        title: "La Importancia de Tu Donación",
        subTitle: `Tu donación es clave para mantener la vitalidad cultural de Macaé. Juntos, podemos
        asegurar que el Centro Cultural Rinha das Artes siga siendo un punto de encuentro para
        la diversidad cultural, la expresión artística y la comunidad. Únete a este viaje y
        sé un agente de cambio cultural en nuestra ciudad.`,
        button: "Realiza tu donación hoy",
      },
      COMP_ALLYA: {
        title: '¡Venga y forme parte de nuestro ecosistema del bien!',
        description: `
          <p>Su donación es muy importante para nosotros y marca la diferencia. El proceso de donación es sencillo, seguro y fácil. O dinheiro doado é revertido para as nossas ações sociais e filantrópicas. Puede donar una vez o cada mes de forma periódica. Tanto si su donación es grande como pequeña, sepa que es una prueba de su compromiso con un mundo mejor para todos. Únase hoy a nosotros para crear un futuro en el que prevalezca el poder perdurable del amor.</p>
          <p><strong>Dona todos los meses a Grupo Acto</strong> y participa en nuestro <strong>Clube do Bem</strong>, una asociación altruista y filantrópica entre <strong>Propago y Allya.</strong></p>
          <p>Con el Clube do Bem de Propago ahorrarás dinero, harás tu día a día más práctico y lo mejor de todo, ¡tu ahorro puede convertirse en donación!</p>
          <p>¡Disfrutarás de una <strong>serie de descuentos</strong>, ventajas y beneficios en <strong>más de 30.000 establecimientos</strong> como: Netshoes, Magazine Luiza, Cinemark, Droga Raia, EspaçoLaser y muchos más!</p>
        `,
        list: [
          {
            area: 'Belleza y bienestar -',
            discount: 'Hasta el 80% de descuento',
          },
          {
            area: 'Ocio -',
            discount: 'Hasta el 50% de descuento',
          },
          {
            area: 'Restaurantes -',
            discount: 'Hasta el 35% de descuento',
          },
          {
            area: 'Cursos y Universidades -',
            discount: 'Hasta el 80% de descuento',
          },
        ],
        bottomDescription: `<p>¿Ves cómo tu economía puede hacer bien a todo el ecosistema?</p>`,
        text: `El objetivo de <strong style="color: #F73D6A;">Allya</strong> es generar ahorro y practicidad en la vida cotidiana. Con sus ventajas, es posible un estilo de vida más económico e inteligente`,
      },
      CHEC00001: {
        title: "Su donación <strong>transformará la vida</strong> de alguien.",
        legend: "Apoyo",
      },
      FAQS00001: {
        title: "<strong>Preguntas frecuentes</strong> sobre la donación:",
        cards: [
          {
            title: "¿Grupo Acto acepta donaciones en especie (ropa, alimentos, agua, etc.)?",
            subTitle:
              "Desafortunadamente, no podemos aceptar donaciones en especie.",
          },
          {
            title: "¿Cómo puedo estar seguro de que mi donación en línea ha sido aceptada?",
            subTitle:
              "Si ingresaste una dirección de correo electrónico válida, recibirás un mensaje de confirmación de tu donación. Si no recibes un correo electrónico en 15 minutos, contáctenos.",
          },
          {
            title: "¿Cuáles son los métodos de pago para las donaciones en el sitio web?",
            subTitle:
              "Puedes hacer tu donación, ya sea puntual o recurrente con tarjeta de crédito.",
          },
          {
            title: "¿Por qué la donación recurrente es la mejor opción?",
            subTitle:
              "La donación mensual es la mejor opción tanto para Grupo Acto como para nuestros seguidores. Ella nos permite tener una base de apoyo confiable y proporciona financiamiento predecible. Para nuestros seguidores, es la forma más fácil de donar, ya que la tarjeta de crédito descuenta la donación automáticamente..",
          },
          {
            title:
              "¿Es segura la página de donaciones?",
            subTitle:
              "Estamos orgullosos de cumplir con estrictos estándares de seguridad de datos para procesar tu donación en línea.",
          },
        ],
      },
      COMP_GEOL: {
        title: `Haga una visita a nosotros!`,
        iframeTitle: "Ubicación de la oficina de Grupo Acto"
      },
      FOOT00001: {
        copyright:
          "Copyright © 2024 - Grupo Acto® - Todos los derechos pueden ser compartidos con usted.",
        copyrightSpan: "Pregúntanos cómo.",
      },
      SectionCookies: {
        title: "Grupo Acto preocupase contigo e tu privacidad.",
        subTitle:
          "Este sitio web utiliza cookies y otras tecnologias para mejorar y optimizar la experiencia del usuario, bien como para analizar la atividade de los visitantes y su frequência de utilización. Si no aceptas, utilizaremos únicamente las cookies con finalidad técnica, para que la página web funcione.",
        button: "Acepto",
      },
      Checkout: i18n_translations.es.translations,
    }
  },
};

export default i18n;