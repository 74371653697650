import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px 0;
  background-color: #273485;
  
  .col {
    height: 600px;
  }
  
  .arrow {
    height: 2em;
    width: 2em;
    cursor: pointer;
  }

  .button {
    margin-top: 40px; 
  }

  .bottom-description {
    color: #E7DB32;
  }
`;