import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Carousel from "../../Carousel";

// Components
import Button from "../../Button";
import SectionTitle from "../../SectionTitle";

// Styles
import { Wrapper } from "./styles";

const COMP00018 = ({ dirName }) => {
  const { t } = useTranslation();

  const cardsText = t(`${COMP00018.displayName}.cards`, { returnObjects: true });

  return (
    <Wrapper id={COMP00018.displayName}>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <Col xs={9} sm={12} className="d-flex justify-content-center">
          <SectionTitle titleColor="#E7DB32">
            {t(`${COMP00018.displayName}.title`)}
          </SectionTitle>
        </Col>
      </div>
      <Col className="d-flex flex-row justify-content-center g-0 w-100 h-100">
        <Col xs={11} sm={10} md={8} lg={6} xl={4}>
          <Carousel
            slides={cardsText}
            imagePath={`${dirName}/${COMP00018.displayName}`}
          />
        </Col>
      </Col>
      <Row>
        <Col 
          xs={10}
          sm={10}
          className="w-100 d-flex justify-content-center my-2 text-center fs-4 bottom-description"
        >
          <p>
            <strong>
              {t(`${COMP00018.displayName}.bottomDescription`)}
            </strong>
          </p>
        </Col>
      </Row>
      <Row className="justify-content-center g-0 w-100 h-100 button">
        <Col
          xs={10}
          sm={10}
          lg={12}
          className="d-flex justify-content-center"
        >
          <Button
            primaryColor="#E7DB32"
            secondaryColor="#273485"
            icon={false}
            buttonWidthVariable
          >
            {t(`${COMP00018.displayName}.button`)}
          </Button>
        </Col>
      </Row>
    </Wrapper>
  );
};
COMP00018.displayName = 'COMP00018'

export default COMP00018;