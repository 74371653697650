import styled from "styled-components";

export const CarouselContainer = styled.div`
    .carousel-container, .small-carousel-container {
        position: relative;
        height: 500px;
        width: 100%;
        margin-bottom: 20px;
        perspective: 1000px;
        transform-style: preserve-3d;
        overflow: hidden
    }

    .card-container {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #FFF;
        width: 300px;
        max-width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        margin: auto;
        cursor: pointer;
        transition: transform .55s ease;

        h3 {
            font-size: 20px;
            font-weight: bold;
            text-align: center;
        }
    }

    button {
        position: relative;
        border: none;
        background: transparent;
        z-index: 2;
    }

    .card-item {
        position: relative;
        height: 100%;
        border-radius: 10px;
        padding: 10px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;

        ul {
            margin: 0;
            padding: 10px;
        }

        li {
            list-style: none;
            margin: 0;
        }
    }

    [data-active-card-item="0"] [data-card-item="3"],
    [data-active-card-item="1"] [data-card-item="4"],
    [data-active-card-item="2"] [data-card-item="0"],
    [data-active-card-item="3"] [data-card-item="1"],
    [data-active-card-item="4"] [data-card-item="2"]{
        transform: translate3d(-200%, 25%, -250px) scale(.333333);
        z-index: 0;
        opacity: .1;
    }

    [data-active-card-item="0"] [data-card-item="4"], 
    [data-active-card-item="1"] [data-card-item="0"], 
    [data-active-card-item="2"] [data-card-item="1"], 
    [data-active-card-item="3"] [data-card-item="2"], 
    [data-active-card-item="4"] [data-card-item="3"] {
        transform: translate3d(-110%, 20%, -120px) scale(.666667);
        z-index: 1;
        opacity: .4;
    }

    [data-active-card-item="0"] [data-card-item="0"],
    [data-active-card-item="1"] [data-card-item="1"],
    [data-active-card-item="2"] [data-card-item="2"],
    [data-active-card-item="3"] [data-card-item="3"],
    [data-active-card-item="4"] [data-card-item="4"] {
        transform: translate3d(0, 0, 0);
        z-index: 2;
        opacity: 1;
    }

    [data-active-card-item="0"] [data-card-item="1"],
    [data-active-card-item="1"] [data-card-item="2"],
    [data-active-card-item="2"] [data-card-item="3"],
    [data-active-card-item="3"] [data-card-item="4"],
    [data-active-card-item="4"] [data-card-item="0"] {
        transform: translate3d(110%, 20%, -120px) scale(.666667);
        z-index: 1;
        opacity: .4;
    }

    [data-active-card-item="0"] [data-card-item="2"],
    [data-active-card-item="1"] [data-card-item="3"],
    [data-active-card-item="2"] [data-card-item="4"],
    [data-active-card-item="3"] [data-card-item="0"],
    [data-active-card-item="4"] [data-card-item="1"] {
        transform: translate3d(200%, 25%, -250px) scale(.333333);
        z-index: 0;
        opacity: .1;
    }
    
    [data-active-card-item="0"].small-carousel-container [data-card-item="2"],
    [data-active-card-item="1"].small-carousel-container [data-card-item="0"],
    [data-active-card-item="2"].small-carousel-container [data-card-item="1"]{
        transform: translate3d(-114%, 20%, -120px) scale(.666667);
        z-index: 1;
        opacity: .4;
    }

    [data-active-card-item="0"].small-carousel-container [data-card-item="1"],
    [data-active-card-item="1"].small-carousel-container [data-card-item="2"],
    [data-active-card-item="2"].small-carousel-container [data-card-item="0"]{
        transform: translate3d(114%, 20%, -120px) scale(.666667);
        z-index: 1;
        opacity: .4;
    }

    .card-item__image {
        height: 250px;
        width: 250px;
        margin: 0 auto;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    @media (max-width: 660px) {
        flex-wrap: wrap;
        justify-content: center;

        .carousel-container, .small-carousel-container{
            order: -1;
        } 
    }
`;